.redesign {
  .text {
    &-h1 {
      font-family: $ff-Onest-Regular;
      font-size: $fs-xxl;
      line-height: 136%;
      text-decoration: none;
    }

    &-h1-drawers {
      font-family: $ff-Onest-Regular;
      font-size: $fs-xxl;
      text-decoration: none;
    }

    &-h2 {
      font-family: $ff-Onest-Medium;
      font-size: $fs-xl;
      line-height: 120%;
      text-decoration: none;
    }

    &-h3 {
      font-family: $ff-Onest-Regular;
      font-size: $fs-l;
      line-height: 120%;
      text-decoration: none;
    }

    &-h4-item-name {
      font-family: $ff-Onest-Medium;
      font-size: $fs-m;
      text-decoration: none;
    }

    &-h4 {
      font-family: $ff-Onest-Medium;
      font-size: $fs-m;
      line-height: 120%;
    }

    &-h5 {
      font-family: $ff-Onest-Medium;
      font-size: $fs-xs;
      text-decoration: none;
    }

    &-body {
      font-family: $ff-Onest-Regular;
      font-size: $fs-m;
      line-height: 140%;
      text-decoration: none;
    }

    &-accent {
      font-family: $ff-Onest-SemiBold !important;
      font-size: $fs-m !important;
      text-decoration: none !important;
    }

    &-lists {
      font-family: $ff-Onest-Regular;
      font-size: $fs-m;
      line-height: 130%;
      text-decoration: none;
    }

    &-tag {
      font-family: $ff-Onest-Regular;
      font-size: $fs-xs;
      text-decoration: none;
    }

    &-tag-accent {
      font-family: $ff-Onest-Medium !important;
      font-size: $fs-xs !important;
      text-decoration: none !important;
    }

    &-profile-icon {
      font-family: $ff-Onest-Bold;
      font-size: $fs-xs;
    }

    &-primary-button {
      font-family: $ff-Onest-SemiBold;
      font-size: $fs-m;
      line-height: 20px;
      text-decoration: none;
    }

    &-secondary-button {
      font-family: $ff-Onest-SemiBold;
      font-size: $fs-xs;
      text-decoration: none;
    }

    &-controls {
      font-family: $ff-Onest-Regular;
      font-size: $fs-s;
      text-decoration: none;
    }

    &-controls-accent {
      font-family: $ff-Onest-Medium;
      font-size: $fs-s;
      text-decoration: none;
    }
  }

  .color {
    &-bright-green {
      color: $color-general-bright-green;
    }

    &-dark-grey {
      color: $color-general-dark-grey;
    }

    &-light-grey {
      color: $color-outline-light-grey;
    }

    &-white {
      color: $color-general-white;

      &-40 {
        color: $color-general-white;
        opacity: 0.4;
      }
    }

    &-black {
      color: $color-general-black;
    }

    &-red {
      color: $color-general-red;
    }

    &-dark-green {
      color: $color-general-dark-green;
    }
  }

  .button {
    &-lg {
      padding: 0 24px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border: none;
      border-radius: 44px;
      box-shadow: none;

      & span {
        font-family: $ff-Onest-SemiBold;
        font-size: $fs-m;
        line-height: 20px;
      }

      & .ant-btn-icon {
        margin: 0 !important;
        line-height: 0;
      }
    }

    &-sm {
      padding: 0 20px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border: none;
      border-radius: 44px;
      box-shadow: none;

      & span {
        font-family: $ff-Onest-SemiBold;
        font-size: $fs-xs;
      }

      & .ant-btn-icon {
        margin: 0 !important;
        line-height: 0;
      }
    }

    &-text {
      padding: 0 6px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      border: none;
      border-radius: 40px;
      box-shadow: none;

      & span {
        font-family: $ff-Onest-SemiBold;
        font-size: $fs-xs;
        text-decoration: none;
        color: $color-general-dark-green;
      }

      &:hover {
        & span {
          color: $color-general-bright-green;
        }

        & path {
          stroke: $color-general-bright-green;
        }
      }

      &:active {
        background-color: $color-background-pale-green;

        & span {
          color: $color-general-bright-green;
        }

        & path {
          stroke: $color-general-bright-green;
        }
      }

      &:disabled {
        background-color: $color-general-white;

        & span {
          color: $color-outline-grey;
        }

        & path {
          stroke: $color-outline-grey;
        }
      }
    }

    &-circle {
      padding: 0;
      min-width: 44px;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 44px;
      box-shadow: none;

      &.secondary {
        background-color: $color-hover-light-grey;

        &:hover {
          background-color: $color-pressed-light-grey;
        }

        &:active {
          background-color: $color-background-grey;
        }

        &:disabled {
          background-color: $color-pressed-light-grey;

          & path {
            stroke: $color-outline-grey;
          }
        }

        &.badge-active {
          position: relative;

          &::after {
            position: absolute;
            top: -2px;
            right: -2px;
            content: '';
            width: 16px;
            height: 16px;
            background-color: $color-general-bright-green;
            border: 3px solid $color-general-white;
            border-radius: 50%;
          }
        }
      }
    }

    &-icon {
      padding: 0;
      width: 20px;
      height: 20px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      box-shadow: none;
    }

    &-chip {
      padding: 8px 16px;
      max-width: 432px;
      height: 36px;
      background-color: $color-hover-light-grey;
      border: none;
      border-radius: 36px;
      box-shadow: none;

      & span {
        width: 100%;
        font-family: $ff-Onest-Regular;
        font-size: $fs-s;
        text-decoration: none;
        line-height: 22px;
        color: $color-general-black;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover {
        background-color: $color-background-pale-green;

        & span {
          color: $color-general-bright-green;
        }
      }

      &:disabled {
        background-color: $color-hover-light-grey;

        & span {
          color: $color-outline-grey;
        }
      }
    }

    &-lg.primary,
    &-sm.primary,
    &-circle.primary {
      background-color: $color-general-dark-green;

      & span {
        color: $color-general-white;
      }

      &:hover {
        background-color: $color-hover-dark-green;
      }

      &:active {
        background-color: $color-pressed-dark-green;
      }

      &:disabled {
        background-color: $color-pressed-light-grey;

        & span {
          color: $color-outline-grey;
        }

        & path {
          stroke: $color-outline-grey;
        }
      }
    }

    &-lg.secondary,
    &-sm.secondary {
      background-color: $color-general-white;
      border: 1px solid $color-general-dark-grey;

      & span {
        color: $color-general-black;
      }

      &:hover {
        background-color: $color-hover-light-grey;
        border-color: $color-outline-dark-grey-hover;
      }

      &:active {
        background-color: $color-pressed-light-grey;
        border-color: $color-general-black;
      }

      &:disabled {
        background-color: $color-general-white;
        border-color: $color-outline-grey;

        & span {
          color: $color-outline-grey;
        }
      }
    }

    &-lg.green {
      background-color: $color-background-light-bright-green;

      & span {
        color: $color-general-white;
      }

      &:hover {
        background-color: $color-hover-light-bright-green;
      }

      &:active {
        background-color: $color-pressed-light-bright-green;
      }

      &:disabled {
        background-color: $color-background-light-bright-green;

        & span {
          color: $color-disabled-white;
        }
      }
    }

    &-lg.danger,
    &-sm.danger {
      background-color: $color-background-red;

      & span {
        color: $color-general-white;
      }

      &:hover {
        background-color: $color-hover-red;
      }

      &:active {
        background-color: $color-pressed-red;
      }

      &:disabled {
        background-color: $color-disabled-red;

        & span {
          color: $color-disabled-white;
        }
      }
    }

    &-lg.success,
    &-sm.success {
      background-color: $color-general-bright-green;

      & span {
        color: $color-general-white;
      }

      &:hover {
        background-color: $color-hover-bright-green;
      }

      &:active {
        background-color: $color-pressed-bright-green;
      }

      &:disabled {
        background-color: $color-pressed-light-grey;

        & span {
          color: $color-outline-grey;
        }

        & path {
          stroke: $color-outline-grey;
        }
      }
    }

    &-sm.black {
      background-color: transparent;
      border: 1px solid $color-general-dark-grey !important;

      & span {
        color: $color-general-white;
      }
    }

    &-lg.deleting,
    &-sm.deleting {
      background-color: $color-background-pale-red;

      & span {
        color: $color-general-red;
      }

      &:hover {
        background-color: $color-hover-pale-red;
      }

      &:active {
        background-color: $color-pressed-pale-red;
      }

      &:disabled {
        background-color: $color-background-pale-red;

        & span {
          color: $color-disabled-red;
        }

        & path {
          stroke: $color-disabled-red;
        }
      }
    }

    &-circle.transparent {
      &:hover {
        background-color: $color-pressed-light-grey;
      }

      &:active {
        background-color: $color-background-grey;
      }
    }
  }

  .custom-dropdown {
    padding: 20px;
    background-color: $color-general-white;
    border-radius: 22px;
    border: 1px solid $color-outline-grey;
    box-shadow: 0 0 24px 12px rgba(41, 62, 53, 0.06);

    &__title {
      margin-bottom: 20px;
      display: inline-block;
    }

    &__scrollable {
      max-height: 252px;
      overflow-y: scroll;
    }

    & .ant-dropdown-menu {
      padding: 0 !important;
      border: none;
      box-shadow: none;
    }
  }

  .table {
    &-expandable {
      & .ant-table {
        &-row {
          cursor: pointer;

          &.expanded {
            box-shadow: none !important;

            & .ant-table-cell {
              border-top: 1px solid $color-general-bright-green;
              border-bottom: none !important;
            }
          }
        }

        &-expanded-row {
          cursor: pointer;

          & .ant-table-cell {
            background-color: transparent !important;
            border-bottom-color: $color-general-bright-green !important;
          }
        }
      }
    }

    &-hover {
      & .ant-table {
        &-row {
          cursor: pointer;

          &:hover {
            box-shadow: 0px 0px 12px rgba(41, 62, 53, 0.12);
            clip-path: inset(-12px 0px -12px 0px);
          }
        }
      }
    }

    &-hover-bg {
      & .ant-table {
        &-row {
          cursor: pointer;

          & .ant-table-cell {
            position: relative;
            border-bottom: none !important;

            &:not(.table-hover-bg .ant-table-row:last-child .ant-table-cell)::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              display: block;
              width: 100%;
              height: 1px;
              background-color: $color-outline-invisible-grey;
            }

            &:first-child {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }

            &:last-child {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }

          &:hover {
            background-color: $color-hover-light-grey;
          }

          &:active {
            background-color: $color-pressed-light-grey;
          }
        }
      }
    }

    &-end-of-list {
      & .ant-table {
        padding-bottom: 80px;

        &-row {
          &:last-child {
            & .ant-table-cell {
              border-bottom-color: $color-general-bright-green !important;
            }
          }
        }
      }
    }

    &-empty {
      flex: 1;

      & .ant-spin {
        &-nested-loading {
          height: 100%;
        }

        &-container {
          height: 100%;
        }
      }

      & .ant-table {
        height: 100%;

        &-container {
          height: 100%;
        }

        &-content {
          height: 100%;
        }

        & table {
          height: 100%;
        }
      }
    }
  }

  .input-with-label {
    display: flex;
    flex-direction: column-reverse;

    & .ant-input-affix-wrapper:focus-within + .input-label {
      color: $color-general-bright-green;
    }

    & .ant-input:focus + .input-label {
      color: $color-general-bright-green;
    }

    & .ant-select:focus-within + .input-label {
      color: $color-general-bright-green;
    }
  }

  .input-with-value {
    & .ant-input {
      color: $color-general-bright-green;

      &:focus {
        color: initial;
      }
    }
  }

  .w {
    &-100 {
      width: 100%;
    }
  }

  .scrollbar {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background-color: $color-general-white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-outline-grey;
      border-radius: 20px;
      border: 3px solid transparent;
      background-clip: content-box;

      &:hover {
        background-color: $color-outline-dark-grey-hover;
      }
    }

    &-bordered {
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background-color: $color-general-white;
        border-style: solid;
        border-color: $color-outline-light-grey;
        border-left-width: 1px;
        border-right-width: 1px;
        border-top: none;
        border-bottom: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-outline-grey;
        border-radius: 20px;
        border: 3px solid transparent;
        background-clip: content-box;

        &:hover {
          background-color: $color-outline-dark-grey-hover;
        }
      }
    }
  }

  &.modal {
    & .ant-modal {
      &-mask {
        background: $color-overlay-light-overlay;
      }

      &-content {
        padding: 20px;
        border: 1px solid $color-background-dark-grey;
        border-radius: 22px;
        box-shadow: 0px 0px 24px 12px rgba(41, 62, 53, 0.06);
      }

      &-close {
        top: 20px;
        width: 20px;
        height: 20px;

        &:hover {
          background-color: transparent;

          & .anticon-close {
            color: $color-general-dark-grey;
          }
        }
      }

      &-header {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      &-title {
        font-family: $ff-Onest-Medium;
        font-size: $fs-m;
        font-weight: 500;
        line-height: 120%;
      }

      &-footer {
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        border-top: 1px solid $color-outline-invisible-grey;

        & .ant-btn {
          margin-inline-start: 0px !important;
        }
      }
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $color-outline-invisible-grey;
}

.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.calendar-custom-header {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__label {
    text-transform: capitalize;
  }
}

.custom-popover {
  &__header {
    display: flex;
    justify-content: flex-end;

    & rect {
      fill: $color-general-dark-grey;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__radio {
    & .ant-radio {
      &-inner {
        display: none;
      }

      &-wrapper {
        & span:last-child {
          padding: 0 !important;
        }

        &-checked {
          & .supply-status {
            border: 1px solid $color-general-bright-green;
          }
        }
      }
    }
  }

  &__divider {
    margin: 20px 0;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
}

.drawer {
  &__header {
    padding: 40px;
    display: flex;
    align-items: center;
  }

  &__header-with-extra {
    flex-direction: row-reverse;
    gap: 12px;

    & .ant-drawer-header-title {
      flex: none;
    }
  }

  &__title {
    margin-bottom: 52px;
    font-family: $ff-Onest-Regular;
    font-size: $fs-xxl;
    line-height: 136%;
    text-decoration: none;
  }

  &__body {
    flex: 1;
  }

  &__footer {
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.password-requirements {
  padding-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;

  &__item {
    display: flex;
    align-items: center;
    gap: 8px;

    &-label {
      font-family: $ff-Onest-Regular;
      font-size: $fs-xs;
      text-decoration: none;
      color: $color-general-dark-grey;
    }

    & rect {
      fill: $color-outline-grey;
    }

    &.checked {
      & .password-requirements__item-label {
        color: $color-general-bright-green;
      }

      & rect {
        fill: $color-general-bright-green;
      }
    }
  }
}

.icon {
  &-document {
    &-dark-grey {
      & path {
        stroke: $color-general-dark-grey;
      }

      & path:nth-child(3) {
        stroke: transparent;
        fill: $color-general-dark-grey;
      }
    }

    &-bright-green {
      & path {
        stroke: $color-general-bright-green;
      }

      & path:nth-child(3) {
        stroke: transparent;
        fill: $color-general-bright-green;
      }
    }
  }

  &-package {
    &-bright-green {
      & path {
        stroke: transparent;
        fill: $color-general-bright-green;
      }

      & rect {
        fill: transparent;
      }
    }

    &-dark-grey {
      & path {
        stroke: transparent;
        fill: $color-general-dark-grey;
      }

      & rect {
        fill: transparent;
      }
    }
  }

  &-options {
    &-dark-grey circle {
      fill: $color-general-dark-grey;
    }
  }

  &-arrow-left-short {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }
  }

  &-arrow-right-short {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }

    &-light-grey path {
      stroke: $color-outline-light-grey;
    }
  }

  &-arrow-down-short {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }

    &-bright-green path {
      stroke: $color-general-bright-green;
    }
  }

  &-arrow-up-short {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }
  }

  &-arrow-left-long {
    &-bright-green rect {
      fill: $color-general-bright-green;
    }
  }

  &-arrow-right-long {
    &-dark-grey {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-dark-grey;
      }
    }
  }

  &-arrow-right-long {
    &-white rect {
      fill: $color-general-white;
    }

    &-bright-green rect {
      fill: $color-general-bright-green;
    }
  }

  &-tool {
    &-dark-grey {
      & path {
        stroke: $color-general-dark-grey;

        &:nth-child(4) {
          fill: $color-general-dark-grey;
        }
      }
    }
  }

  &-bin {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }

    &-dark-green path {
      stroke: $color-general-dark-green;
    }

    &-pale-red path {
      stroke: $color-general-red;
    }
  }

  &-delivery {
    &-out_of_stock {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-red;
      }
    }

    &-in_stock {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-bright-green;
      }
    }

    &-available_on_request {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-yellow;
      }
    }

    &-created {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-yellow;
      }
    }

    &-cancelled {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-red;
      }
    }

    &-pending {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-bright-green;
      }
    }

    &-delivered {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-bright-green;
      }
    }

    &-delivery {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-bright-green;
      }
    }

    &-placed {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-bright-green;
      }
    }

    &-approved {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-bright-green;
      }
    }

    &-processing {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-bright-green;
      }
    }

    &-dark-grey {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-dark-grey;
      }
    }

    &-bright-green {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-bright-green;
      }
    }
  }

  &-info-red:hover path:first-child {
    fill: $color-general-red;
  }

  &-info-yellow:hover path:first-child {
    fill: $color-general-yellow;
  }

  &-info-green:hover path:first-child {
    fill: $color-general-bright-green;
  }

  &-close {
    &-dark-grey {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-dark-grey;
      }
    }

    &-white rect {
      fill: $color-general-white;
    }

    &-input {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-dark-grey;
      }
    }
  }

  &-close-grey {
    &-dark-grey {
      rect {
        fill: $color-background-dark-grey;
      }

      & g rect {
        fill: $color-general-black;
      }
    }

    &-blue {
      & rect {
        fill: $color-general-blue;
      }

      & g rect {
        fill: $color-general-black;
      }
    }

    &-red {
      & rect {
        fill: #f8caca;
      }

      & g rect {
        fill: $color-general-black;
      }
    }
  }

  &-copy {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }
  }

  &-cart {
    &-bright-green path {
      stroke: $color-general-bright-green;
    }

    &-white path {
      stroke: $color-general-white;
    }
  }

  &-sort {
    &-dark-grey path {
      fill: $color-general-dark-grey;
    }
  }

  &-catalog {
    &-bright-green path {
      stroke: $color-general-bright-green;
    }
  }

  &-affiliate {
    &-dark-grey path {
      fill: $color-general-dark-grey;
    }

    &-bright-green path {
      fill: $color-general-bright-green;
    }
  }

  &-user {
    &-dark-grey path {
      fill: transparent;
      stroke: $color-general-dark-grey;
    }

    &-bright-green path {
      fill: transparent;
      stroke: $color-general-bright-green;
    }
  }

  &-edit {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }
  }

  &-contract {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }

    &-bright-green path {
      stroke: $color-general-bright-green;
    }
  }

  &-calendar {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }
  }

  &-eye {
    &-dark-grey {
      & rect {
        fill: transparent;
      }

      & path {
        fill: $color-general-dark-grey;
      }
    }
  }

  &-history {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }
  }

  &-settings {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }
  }

  &-search {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
      fill: transparent;
    }

    &-white path {
      stroke: $color-general-white;
      fill: transparent;
    }
  }

  &-legal-entity {
    &-bright-green path {
      stroke: $color-general-bright-green;
    }

    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }
  }

  &-plus {
    &-white path {
      stroke: $color-general-white;
    }

    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }
  }

  &-menu {
    &-white rect {
      fill: $color-general-white;
    }
  }

  &-link {
    &-white path {
      stroke: $color-general-white;
    }
  }

  &-unlink {
    &-red path {
      stroke: $color-general-red;

      &:last-child {
        stroke: transparent;
      }
    }
  }

  &-percentage {
    &-dark-grey path {
      stroke: $color-general-dark-grey;
    }
  }

  &-discount {
    &-bright-green {
      & path {
        stroke: $color-general-bright-green;

        &:nth-child(1) {
          fill: $color-general-bright-green;
          stroke: transparent;
        }

        &:nth-child(2) {
          fill: $color-general-bright-green;
          stroke: transparent;
        }
      }
    }
  }
}

.mb {
  &-0 {
    margin-bottom: 0px;
  }

  &-4 {
    margin-bottom: 4px;
  }

  &-6 {
    margin-bottom: 6px;
  }

  &-8 {
    margin-bottom: 8px;
  }

  &-10 {
    margin-bottom: 10px;
  }

  &-12 {
    margin-bottom: 12px;
  }

  &-16 {
    margin-bottom: 16px;
  }

  &-20 {
    margin-bottom: 20px;
  }

  &-24 {
    margin-bottom: 24px;
  }

  &-32 {
    margin-bottom: 32px;
  }

  &-40 {
    margin-bottom: 40px;
  }

  &-44 {
    margin-bottom: 44px;
  }

  &-52 {
    margin-bottom: 52px;
  }

  &-72 {
    margin-bottom: 72px;
  }

  &-132 {
    margin-bottom: 132px;
  }

  &-172 {
    margin-bottom: 172px;
  }
}

.property-range {
  &__container {
    display: flex;
    align-items: center;
    border: 1px solid $color-outline-grey;
    border-radius: 44px;

    & .ant-input-number-affix-wrapper {
      border: none;

      &:first-child {
        padding-right: 12px;
      }

      &:last-child {
        padding-left: 12px;
      }
    }

    &:hover {
      border-color: $color-outline-dark-grey-hover;
    }

    &:focus-within {
      border-color: $color-general-bright-green;
    }

    &.has-error {
      border-color: $color-general-red;
    }
  }
}

.error {
  padding: 16px;
  background-color: $color-background-black;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.textarea {
  padding: 20px !important;
  height: auto !important;
  border-radius: 30px !important;
  scrollbar-width: none;
}

.checkbox-with-description {
  &__label {
    font-family: $ff-Onest-Medium;
    font-size: $fs-s;
  }

  &__description {
    margin-top: 2px;
    padding: 0px;
    font-family: $ff-Onest-Regular;
    font-size: $fs-xs;
    color: $color-general-dark-grey;
  }

  &.ant-checkbox-wrapper-disabled {
    & .checkbox-with-description__label {
      color: initial;
    }

    &checkbox-with-description__description {
      color: initial;
    }
  }

  & .ant-checkbox + span {
    padding: 0px 0px 0px 12px !important;
  }
}

.select-with-search {
  &__custom-option {
    &-user {
      display: flex;
      align-items: center;
      gap: 12px;

      &:hover {
        & .icon-background {
          background-color: $color-background-dark-grey;
        }
      }
    }
  }

  & .ant-select-item-option-selected {
    & .select-with-search__custom-option {
      &-user-name {
        font-family: $ff-Onest-SemiBold;
      }
    }
  }
}

.autosize-autocomplete {
  padding: 5px 0;
  height: auto;
  border-radius: 22px !important;

  & .ant-select-clear {
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: $color-general-white;
    transform: none;
  }

  & .ant-input {
    padding: 4px 0 0;
    border: none;
    border-radius: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.autosize-input {
  padding: 5px 40px 5px 20px !important;
  min-height: 44px;
  border-radius: 22px !important;

  & .ant-input {
    padding: 4px 0 0;
    border-radius: 0;
    font-size: $fs-s !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .ant-input-clear-icon {
    height: 100%;
    inset-inline-end: 20px !important;
    inset-block-start: auto !important;
    background-color: $color-general-white;
  }
}

.collapse {
  border: none;
  border-radius: 0px;

  &__content-row {
    padding: 16px 0 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(&:last-child) {
      border-bottom: 1px solid $color-background-dark-grey;
    }
  }
}
