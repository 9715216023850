// Color variables
$color-white: #ffffff;
$color-ghost-white: #f7f9fc;
$color-lavender: #ededfc;
$color-metallic-silver: #a1a9b8;
$color-maximum-blue-purple: #9e9bf5;
$color-majorelle-blue: #5e5adb;
$color-ocean-blue: #4945c4;
$color-black: #000000;
$color-dark-electric-blue: #5a6376;
$color-bright-gray: #e9edf5;
$color-roman-silver: #808a94;
$color-independence: #464f60;
$color-begonia: #f76f7f;
$color-red: #ff0000;
$color-rusty-red: #d1293d;
$color-windsor-tan: #aa5b00;
$color-light-silver: #d9d9d9;
$color-brandeis-blue: #0167ff;
$color-azure: #0085ff;
$color-islamic-green: #009618;
$color-anti-flash-white: #f0f0f0;
$color-honeydew: #e1fcef;
$color-lavender-blush: #ffedef;
$color-amazon: #387d61;
$color-chinese-silver: #c8ccd0;
$color-palm-leaf: #69a443;
$color-bud-green: #79b851;
$color-cultured: #f5f5f5;
$color-manatee: #9ba3ab;
$color-alabaster: #e9f3e2;
$color-papaya-whip: #fdf3d8;
$color-harvest-gold: #db9f00;
$color-linen: #fdeded;
$color-paradise-pink: #df4058;
$color-bright-gray: #eeeff2;
$color-deep-aquamarine: #3e896b;
$color-deep-aquamarine-passed: #419070;

// Font family variables
$fontFamily-Roboto-Light: 'Roboto-Light', sans-serif;
$fontFamily-Roboto-Regular: 'Roboto-Regular', sans-serif;
$fontFamily-Roboto-Medium: 'Roboto-Medium', sans-serif;
$fontFamily-Roboto-Bold: 'Roboto-Bold', sans-serif;
$fontFamily-Onest-Regular: 'Onest-Regular', sans-serif;
$fontFamily-Onest-Medium: 'Onest-Medium', sans-serif;
$fontFamily-Onest-SemiBold: 'Onest-SemiBold', sans-serif;
$fontFamily-Onest-Bold: 'Onest-Bold', sans-serif;

// Font size variables
$fontSize-xsm: 12px;
$fontSize-sm: 14px;
$fontSize-md: 16px;
$fontSize-xmd: 18px;
$fontSize-lg: 24px;

// Media breakpoints (max-width)
$screen-maxWidth-sm: 576px;
$screen-maxWidth-md: 768px;
$screen-maxWidth-lg: 992px;
$screen-maxWidth-xl: 1200px;
$screen-maxWidth-xxl: 1600px;

// REDESIGN
// General colors
$color-general-dark-green: #387d61;
$color-general-bright-green: #69a443;
$color-general-white: #ffffff;
$color-general-dark-grey: #808a94;
$color-general-black: #2f3337;
$color-general-red: #df4058;
$color-general-yellow: #db9f00;
$color-general-blue: #c6ddf1;
// General colors/Background
$color-background-grey: #e8eaed;
$color-background-dark-grey: #dcdfe1;
$color-background-pale-yellow: #fdf3d8;
$color-background-pale-green: #e9f3e2;
$color-background-pale-red: #fdeded;
$color-background-pale-purple: #e8eaff;
$color-background-pale-blue: #e8f4ff;
$color-background-yellow: #f6c543;
$color-background-light-bright-green: #79b851;
$color-background-red: #ec6665;
$color-background-black: rgba(19, 20, 22, 0.92);
// General colors/Background/States
$color-hover-dark-green: #3e896b;
$color-pressed-dark-green: #419070;
$color-hover-bright-green: #72b148;
$color-pressed-bright-green: #77b74e;
$color-hover-light-bright-green: #84be60;
$color-pressed-light-bright-green: #8ac167;
$color-hover-red: #ee7877;
$color-pressed-red: #ef8180;
$color-disabled-red: #f6b7b6;
$color-hover-light-grey: #f4f4f6;
$color-pressed-light-grey: #eeeff2;
$color-disabled-white: rgba(255, 255, 255, 0.4);
$color-hover-pale-red: #fde9e9;
$color-pressed-pale-red: #fddfdf;
// General colors/Outline
$color-outline-invisible-grey: #f4f5f6;
$color-outline-light-grey: #dcdfe1;
$color-outline-grey: #c8ccd0;
$color-outline-dark-grey-hover: #9ba3ab;
// Overlay
$color-overlay-light-overlay: rgba(245, 245, 245, 0.68);
// Graphic Pattern/Yellow Monochrome
$color-yellow-monochrome-yellow-1: #f8d247;
$color-yellow-monochrome-yellow-green-1: #dddf4a;
$color-yellow-monochrome-yellow-green-2: #ffff67;
$color-yellow-monochrome-yellow-green-3: #fbec4e;
// Graphic Pattern/Grey Monochrome
$color-grey-monochrome-grey-1: #fbfbfb;
$color-grey-monochrome-grey-2: #e9eaec;
$color-grey-monochrome-grey-3: #f6f7f8;
$color-grey-monochrome-grey-4: #e3e6e9;
$color-grey-monochrome-grey-5: #d3d6da;
$color-grey-monochrome-grey-6: #b2b6bb;

// Font family variables
$ff-Onest-Regular: 'Onest-Regular', sans-serif;
$ff-Onest-Medium: 'Onest-Medium', sans-serif;
$ff-Onest-SemiBold: 'Onest-SemiBold', sans-serif;
$ff-Onest-Bold: 'Onest-Bold', sans-serif;

// Font size variables
$fs-xs: 14px;
$fs-s: 16px;
$fs-m: 18px;
$fs-l: 22px;
$fs-xl: 28px;
$fs-xxl: 40px;

// Media breakpoints (max-width)
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1600px;
