.auth-layout {
  padding: 20px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  &__image {
    width: 60%;
    border-radius: 4px;
    background-image: url(../../../images/redesign/big-pattern.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.auth-card-logo {
  display: flex;
  align-items: center;
  gap: 8px;

  &__icon {
    width: 28px;
    height: 28px;
  }

  & span {
    flex: 1;
    font-family: $ff-Onest-SemiBold;
    font-size: 24px;
  }
}

.auth-card-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.auth-card {
  padding: 40px;
  width: 38.936%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    display: flex;
    justify-content: center;

    &-form {
      width: 100%;
      max-width: 440px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & a {
        font-family: $ff-Onest-SemiBold;
        font-size: $fs-xs;
        color: $color-general-bright-green;
        text-decoration: underline;
      }

      &-title {
        font-family: $ff-Onest-Regular;
        font-size: $fs-xl;
        line-height: 120%;
        letter-spacing: -0.3px;
        text-align: center;
      }
    }

    &-success-container {
      width: 100%;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-btn-container {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}
