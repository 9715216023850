.profile {
  height: 100%;
  display: flex;
  align-items: flex-start;

  &__spin {
    height: 100%;

    & .ant-spin-container {
      height: 100%;
    }
  }

  &__content {
    padding: 92px 80px 0;
    height: 100%;
    flex: 1;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__details {
    margin-bottom: 72px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__block {
    max-width: 1100px;

    &-container {
      display: grid;
      align-items: flex-end;
      gap: 12px;
    }
  }

  &__settings {
    padding: 136px 80px 72px;
    max-width: 580px;
    height: 100%;
    flex: 1;
    border-left: 1px solid $color-outline-grey;

    &-container {
      margin-bottom: 72px;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    &-block {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
      }
    }
  }
}

.user-card {
  & .ant-drawer-footer {
    justify-content: space-between;
  }

  &__roles {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__subdivision {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__contacts {
    &-title {
      margin-bottom: 24px;
      padding-bottom: 12px;
      width: 100%;
      border-bottom: 1px solid $color-outline-grey;
    }

    &-table {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 0;

      &-row {
        display: contents;

        &-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
        }
      }
    }
  }
}

.user-card-role-selector {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: $color-hover-light-grey;
  border: 1px solid transparent;
  border-radius: 60px;
  cursor: pointer;

  &:hover {
    border-color: $color-outline-grey;
  }

  &.opened {
    border-color: $color-outline-grey;
  }
}

.user-edit-card {
  & .ant-drawer {
    &-header-title {
      justify-content: flex-start;
    }

    &-footer {
      justify-content: flex-end;
    }
  }
}

.user-add-modal {
  &__scroll-container {
    height: 285px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  &__list {
    &-item {
      padding: 8px;
      height: auto;
      display: flex;
      align-items: center;
      gap: 12px;
      border: none;
      border-radius: 8px;
      box-shadow: none;
      text-align: start;

      &:hover {
        background-color: $color-hover-light-grey;

        & .icon-background {
          background-color: $color-background-dark-grey;
        }
      }

      &.active {
        background-color: $color-hover-light-grey;

        & .icon-background {
          background-color: $color-background-dark-grey;
        }
      }

      &:not(&:last-child)::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        display: block;
        width: calc(100% - 16px);
        height: 1px;
        background-color: $color-hover-light-grey;
      }
    }

    &-empty {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
