.delivery-badge {
  padding: 6px 10px;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;

  & * {
    line-height: 0;
  }

  &.in_stock {
    background-color: $color-background-pale-green;
    color: $color-general-bright-green;
  }

  &.available_on_request {
    background-color: $color-background-pale-yellow;
    color: $color-general-yellow;
  }

  &.out_of_stock {
    background-color: $color-background-pale-red;
    color: $color-general-red;
  }

  &__tooltip {
    z-index: 10000;
  }

  &__table {
    & .ant-table {
      background-color: transparent;

      &-row .ant-table-cell {
        padding: 2px 8px !important;
        border: none !important;
        font-family: $ff-Onest-Regular !important;
        font-size: $fs-xs !important;
        text-decoration: none !important;
        color: $color-general-white;
        white-space: nowrap;

        & * {
          font-family: $ff-Onest-Regular !important;
          font-size: $fs-xs !important;
          text-decoration: none !important;
          color: $color-general-white;
        }

        &-row-hover {
          background-color: transparent !important;
        }
      }
    }

    &-item {
      &-stock-name {
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-container {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    &-empty {
      width: 200px;
    }
  }
}
