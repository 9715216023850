.user-role-chip {
  padding: 8px 12px;
  width: max-content;
  border-radius: 60px;

  &__name {
    font-family: $ff-Onest-Medium;
    font-size: $fs-xs;
  }

  &.light-grey {
    background-color: $color-hover-light-grey;
  }

  &.pale-purple {
    background-color: $color-background-pale-purple;
  }

  &.pale-blue {
    background-color: $color-background-pale-blue;
  }

  &.pale-green {
    background-color: $color-background-pale-green;
  }
}
