.label {
  width: 100%;
  max-width: max-content;
  display: inline-flex;
  align-items: center;
  gap: 12px;

  &__name {
    flex: 1;
  }

  &.pale-green {
    & .label__name {
      color: $color-general-bright-green;
    }
  }

  &.dark-grey {
    & .label__name {
      color: $color-general-dark-grey;
    }
  }
}
