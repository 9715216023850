.icon-background {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  &.pale-green {
    background-color: $color-background-pale-green;
  }

  &.light-grey {
    background-color: $color-hover-light-grey;
  }

  &.dark-grey {
    background-color: $color-background-dark-grey;
  }

  &.pale-red {
    background-color: $color-background-pale-red;
  }

  &.pale-yellow {
    background-color: $color-background-pale-yellow;
  }
}
