.account-settings {
  position: relative;
  padding: 92px 80px 0px;
  height: 100%;
  flex: 1;
}

.account-settings-name {
  &__value {
    margin-right: 12px;
    display: inline;
  }

  &__edit-btn {
    display: inline-flex !important;
  }
}

.account-contracts-settings {
  &__row {
    margin-bottom: 52px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-container {
      max-width: 800px;
    }

    &-input {
      max-width: 360px;
    }
  }
}

.account-user-list-settings {
  position: relative;

  &__filters {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__table {
    & .ant-table {
      &-row {
        & .ant-table-cell {
          vertical-align: middle;
        }
      }
    }

    &-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }

  &__table-item {
    &-subdivision {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}

.account-user-list-settings-add-user-button {
  &__card {
    & .ant-drawer-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.account-user-list-settings-filter-btn {
  &__dropdown {
    & .ant-checkbox {
      &-wrapper {
        width: 100%;
      }

      & + span {
        padding: 0px 0px 0px 12px !important;
        font-family: $ff-Onest-Medium !important;
        font-size: $fs-s !important;
      }
    }
  }
}

.account-legal-entity-list-settings {
  position: relative;

  &__table {
    & .ant-table {
      &-row {
        & .ant-table-cell {
          vertical-align: middle;
        }
      }
    }

    &-item {
      &-name {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }
}

.account-stock-list-settings {
  position: relative;

  &__table {
    & .ant-table {
      &-row {
        & .ant-table-cell {
          vertical-align: middle;
        }
      }
    }

    &-item {
      &-icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-background-pale-green;
        border-radius: 6px;
      }
    }
  }
}

.account-integrations-settings {
  &__feed-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__collapse {
    &-header {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-content-row {
      &-container {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }
}

.subdivision-settings {
  position: relative;
  padding: 92px 80px 0px;
  height: 100%;
  flex: 1;

  &__breadcrumb {
    min-height: 20px;
  }
}

.subdivision-settings-name {
  &__value {
    margin-right: 12px;
    display: inline;
  }

  &__edit-btn {
    display: inline-flex !important;
  }
}

.subdivision-general-settings {
  position: relative;
  height: 100%;

  &__container {
    display: grid;
    align-items: flex-end;
    grid-template-columns: repeat(2, minmax(280px, 1fr));
    column-gap: 12px;
  }

  &__footer {
    position: absolute;
    left: -80px;
    bottom: 0;
    padding: 20px 40px 40px;
    width: calc(100% + 160px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    background-color: $color-general-white;
    border-top: 1px solid $color-background-dark-grey;
    z-index: 10;
  }
}

.subdivision-address-list-settings {
  &__table {
    & .ant-table {
      &-row {
        & .ant-table-cell {
          vertical-align: middle;
        }
      }
    }

    &-item {
      &-icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-background-pale-green;
        border-radius: 6px;
      }
    }
  }
}

.contract-settings {
  position: relative;
  padding: 92px 80px 0px;
  height: 100%;
  flex: 1;
}

.contract-general-settings {
  &__table {
    & .ant-table {
      &-row {
        & .ant-table-cell {
          vertical-align: middle;
        }
      }
    }

    &-item {
      &-name {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }
}

.contract-assortment-settings {
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.contract-discount-list-settings {
  &__table {
    & .ant-table {
      &-row {
        & .ant-table-cell {
          vertical-align: middle;
        }
      }
    }

    &-item {
      &-name {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }
}

.contract-delivery-time-list-settings {
  &__collapse {
    &-header {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-content-row {
      &-container {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      &-selector {
        position: relative;

        &-icon {
          position: absolute;
          top: 50%;
          left: 12px;
          transform: translateY(-50%);
          z-index: 1;
        }

        & .ant-select {
          width: max-content;
          min-height: 32px;

          &-selector {
            padding-left: 40px !important;
          }

          &-selection-item {
            font-size: $fs-xs;
          }
        }

        &-select {
          & .rc-virtual-list-holder {
            max-height: max-content !important;
          }
        }

        &.bright-green {
          & .contract-delivery-time-list-settings__collapse-content-row-selector-icon {
            & rect {
              fill: transparent;
            }

            & path {
              fill: $color-general-bright-green;
            }
          }

          & .ant-select {
            background-color: $color-background-pale-green !important;

            &-selection-item {
              color: $color-general-bright-green;
            }
          }
        }

        &.light-grey {
          & .contract-delivery-time-list-settings__collapse-content-row-selector-icon {
            & rect {
              fill: transparent;
            }

            & path {
              fill: $color-general-dark-grey;
            }
          }

          & .ant-select {
            background-color: $color-hover-light-grey !important;

            &-selection-item {
              color: $color-general-dark-grey;
            }
          }
        }
      }
    }
  }
}

.settings {
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-start;
}

.settings-sidebar {
  padding: 40px 28px 0px;
  max-width: 400px;
  height: 100%;
  flex: 1;
  border-right: 1px solid $color-outline-grey;
  transition:
    max-width 0.6s ease,
    padding 0.6s ease;

  &__btn {
    margin-bottom: 12px;
    padding: 12px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: none;
    box-shadow: none;

    &.active {
      background-color: $color-hover-light-grey;
    }

    &-container {
      margin-bottom: 4px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &-title-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-text {
      flex: 1;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      word-break: break-all;
    }

    &-toggle {
      width: 24px !important;
      height: 24px !important;

      &:hover {
        background-color: $color-background-grey;
      }

      &:active {
        background-color: $color-background-dark-grey;
      }
    }

    &-create {
      width: 24px !important;
      height: 24px !important;
      display: none !important;

      &:hover {
        background-color: $color-background-grey;
      }

      &:active {
        background-color: $color-background-dark-grey;
      }
    }

    &:hover {
      background-color: $color-hover-light-grey;

      & .settings-sidebar__btn-create {
        display: flex !important;
      }
    }
  }

  &__subdivision-tree {
    & .ant-tree {
      &-icon__customize {
        display: none !important;
      }

      &-treenode {
        &:hover {
          & .ant-tree-icon__customize {
            display: initial !important;
          }
        }
      }
    }

    &-item {
      &-container {
        display: flex;
        align-items: center;
        gap: 12px;

        & .ant-btn {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: $color-background-grey;
          }

          &:active {
            background-color: $color-background-dark-grey;
          }
        }
      }
    }
  }

  &__list {
    &-item {
      padding: 12px;
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: 8px;
      border: none;
      box-shadow: none;
      cursor: pointer;

      &-icon {
        width: 20px;
        height: 20px;
      }

      &-label {
        flex: 1;
        display: -webkit-box;
        font-family: $ff-Onest-Medium;
        font-size: $fs-s;
        text-align: start;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        word-break: break-all;
      }

      &:hover {
        background-color: $color-hover-light-grey;
      }

      &.active {
        background-color: $color-hover-light-grey;

        & .settings-sidebar__list-item-label {
          font-family: $ff-Onest-SemiBold;
        }
      }
    }
  }

  &.collapsed {
    padding: 40px 20px 0;
    max-width: 120px;
  }
}

@media (max-width: $screen-xl) {
  .subdivision-general-settings {
    &__container {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }
}
