.notification {
  padding: 18px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  background-color: $color-background-black;
  border-radius: 8px;

  &__container {
    flex: 1;
  }

  &__footer {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }
}
