.chip-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.chip {
  padding: 8px 16px;
  width: max-content;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 36px;

  &__name {
    font-family: $ff-Onest-Regular;
    font-size: $fs-s;
  }

  &__value {
    margin-left: 4px;
    font-family: $ff-Onest-Medium;
    font-size: $fs-s;
  }

  &__clear-btn {
    margin-left: 8px;
    margin-right: -8px;
    border-radius: 50%;
  }

  &.bright-green {
    background-color: $color-general-bright-green;

    & .chip__name {
      color: $color-general-white;
    }

    & .chip__value {
      color: $color-general-white;
    }

    & .chip__clear-btn {
      background-color: $color-background-light-bright-green !important;

      & rect {
        fill: $color-background-light-bright-green;
      }

      & g rect {
        fill: $color-general-white;
      }

      &:hover {
        background-color: $color-pressed-light-bright-green !important;

        & rect {
          fill: $color-pressed-light-bright-green;
        }

        & g rect {
          fill: $color-general-white;
        }
      }
    }
  }

  &.light-grey {
    background-color: $color-hover-light-grey;

    & .chip__name {
      color: $color-general-black;
    }

    & .chip__value {
      color: $color-general-black;
    }

    & .chip__clear-btn {
      background-color: $color-background-dark-grey !important;

      & rect {
        fill: $color-background-dark-grey;
      }

      & g rect {
        fill: $color-general-black;
      }

      &:hover {
        background-color: $color-outline-grey !important;

        & rect {
          fill: $color-outline-grey;
        }

        & g rect {
          fill: $color-general-black;
        }
      }
    }
  }

  &.pale-purple {
    background-color: $color-background-pale-purple;

    & .chip__name {
      color: $color-general-black;
    }

    & .chip__value {
      color: $color-general-black;
    }

    & .chip__clear-btn {
      background-color: $color-background-dark-grey !important;

      & rect {
        fill: #d0d4ef;
      }

      & g rect {
        fill: $color-general-black;
      }

      &:hover {
        background-color: $color-outline-grey !important;

        & rect {
          fill: #bac1f3;
        }

        & g rect {
          fill: $color-general-black;
        }
      }
    }
  }

  &.pale-blue {
    background-color: $color-background-pale-blue;

    & .chip__name {
      color: $color-general-black;
    }

    & .chip__value {
      color: $color-general-black;
    }

    & .chip__clear-btn {
      background-color: $color-background-dark-grey !important;

      & rect {
        fill: $color-general-blue;
      }

      & g rect {
        fill: $color-general-black;
      }

      &:hover {
        background-color: $color-outline-grey !important;

        & rect {
          fill: #a8d1f5;
        }

        & g rect {
          fill: $color-general-black;
        }
      }
    }
  }

  &.red {
    background-color: $color-background-pale-red;

    & .chip__name {
      color: $color-general-black;
    }

    & .chip__value {
      color: $color-general-black;
    }

    & .chip__clear-btn {
      background-color: $color-background-dark-grey !important;

      & rect {
        fill: #f8caca;
      }

      & g rect {
        fill: $color-general-black;
      }

      &:hover {
        background-color: $color-outline-grey !important;

        & rect {
          fill: #f9b6b6;
        }

        & g rect {
          fill: $color-general-black;
        }
      }
    }
  }
}
