.contract-brands-modal {
  & .ant-modal-body {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 160px);
  }

  & .ant-checkbox {
    &-group {
      flex-direction: column;
    }

    &-wrapper {
      padding: 0 14px;

      &:not(.ant-checkbox-wrapper:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &__check-all {
    border-bottom: 1px solid $color-outline-invisible-grey;
  }

  &__scroll-container {
    flex: 1;
    overflow-y: auto;
  }
}

.contract-categories-modal {
  & .ant-modal-body {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 160px);
  }

  & .ant-tree {
    &-switcher {
      order: 1;
    }

    &-switcher-noop {
      display: none;
    }

    &-checkbox {
      margin-right: 10px;
      order: 0;
    }

    &-indent-unit {
      margin-right: 10px;

      &::before {
        top: -12px;
        bottom: -12px;
      }
    }

    &-list-scrollbar {
      display: none;
    }
  }

  &__check-all {
    padding: 0px 14px;
    border-bottom: 1px solid $color-outline-invisible-grey;
  }

  &__scroll-container {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
