.legal-entity-card {
  & .ant-drawer-footer {
    justify-content: space-between;
  }

  &__details {
    &-title {
      margin-bottom: 24px;
      padding-bottom: 12px;
      width: 100%;
      border-bottom: 1px solid $color-outline-grey;
    }

    &-table {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 0;

      &-row {
        display: contents;

        &-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
        }

        &-email-value {
          max-width: 240px;
          overflow: hidden;
          word-break: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.edit-legal-entity-card {
  & .ant-drawer-footer {
    justify-content: flex-end;
  }
}
